import "../../assets/fonts/css/icons.css";
import "./canvas.css";

import AgoraRTC from "agora-rtc-sdk";
import { CloudFunctions } from "../../utils/Firebase";
import React from "react";
import Toast from "utils/Toast";
import { merge } from "lodash";

const tile_canvas = {
	1: ["span 12/span 24"],
	2: ["span 12/span 12/13/25", "span 12/span 12/13/13"],
	3: ["span 6/span 12", "span 6/span 12", "span 6/span 12/7/19"],
	4: ["span 6/span 12", "span 6/span 12", "span 6/span 12", "span 6/span 12/7/13"],
	5: ["span 3/span 4/13/9", "span 3/span 4/13/13", "span 3/span 4/13/17", "span 3/span 4/13/21", "span 9/span 16/10/21"],
	6: ["span 3/span 4/13/7", "span 3/span 4/13/11", "span 3/span 4/13/15", "span 3/span 4/13/19", "span 3/span 4/13/23", "span 9/span 16/10/21"],
	7: ["span 3/span 4/13/5", "span 3/span 4/13/9", "span 3/span 4/13/13", "span 3/span 4/13/17", "span 3/span 4/13/21", "span 3/span 4/13/25", "span 9/span 16/10/21"],
};

/**
 * @prop appId uid
 * @prop transcode attendeeMode videoProfile channel baseMode
 */
class AgoraCanvas extends React.Component {
	constructor(props) {
		super(props);
		this.client = {};
		this.localStream = {};
		this.shareClient = {};
		this.shareStream = {};
		this.state = {
			displayMode: "pip",
			streamList: [],
			readyState: false,
			stateSharing: false,
			selfId: null,
			bigScreenId: null,
		};
	}

	componentWillMount() {
		let $ = this.props;
		// init AgoraRTC local client
		this.client = AgoraRTC.createClient({ mode: $.transcode });
		try {
			this.client.init($.appId, async () => {
				console.log("AgoraRTC client initialized");
				this.subscribeStreamEvents();
				const token = await CloudFunctions.agora_onCall_generateToken($.channel, $.uid);
				if (token == null) {
					window.location.hash = "/";
					Toast.error("Opps, there are some errors.");
					return;
				}
				// this.client.join($.appId, $.channel, $.uid, (uid) => {
				try {
					this.client.join(token, $.channel, $.uid, (uid) => {
						try {
							this.state.uid = uid;
							console.log("User " + uid + " join channel successfully");
							this.setState({ selfId: uid });
							console.log("At " + new Date().toLocaleTimeString());
							// create local stream
							// It is not recommended to setState in function addStream
							this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile);
							this.localStream.init(
								() => {
									if ($.attendeeMode !== "audience") {
										this.addStream(this.localStream, true);
										this.client.publish(this.localStream, (err) => {
											console.log("Publish local stream error: " + err);
										});
									}
									this.setState({ readyState: true });
								},
								(err) => {
									console.log("getUserMedia failed", err);
									this.setState({ readyState: true });
								}
							);
						} catch (err) {}
					});
				} catch (err) {}
			});
		} catch (err) {}
	}

	componentDidMount() {
		// add listener to control btn group
		let canvas = document.querySelector("#ag-canvas");
		let btnGroup = document.querySelector(".ag-btn-group");
		canvas.addEventListener("mousemove", () => {
			if (global._toolbarToggle) {
				clearTimeout(global._toolbarToggle);
			}
			btnGroup.classList.add("active");
			global._toolbarToggle = setTimeout(function () {
				btnGroup.classList.remove("active");
			}, 2000);
		});
	}

	// componentWillUnmount () {
	//     // remove listener
	//     let canvas = document.querySelector('#ag-canvas')
	//     canvas.removeEventListener('mousemove')
	// }

	componentDidUpdate() {
		// rerendering
		let canvas = document.querySelector("#ag-canvas");
		// pip mode (can only use when less than 4 people in channel)
		if (this.state.displayMode === "pip") {
			let no = this.state.streamList.length;
			if (no > 4) {
				this.setState({ displayMode: "tile" });
				return;
			}
			try {
				const allIds = this.state.streamList.map((item) => item.getId());
				if (!allIds.includes(this.state.bigScreenId)) {
					this.setState({ bigScreenId: null });
				}

				let currentSmallCount = 0;
				this.state.streamList.map((item, index) => {
					let id = item.getId();
					let dom = document.querySelector("#ag-item-" + id);
					if (!dom) {
						dom = document.createElement("section");
						dom.setAttribute("id", "ag-item-" + id);
						dom.setAttribute("class", "ag-item");
						canvas.appendChild(dom);
						item.play("ag-item-" + id);
					}

					if (this.state.streamList.length === 1 && index === no - 1) {
						// big screen self if there is only 1 stream
						dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
					} else if (this.state.bigScreenId == null && id !== this.state.selfId) {
						// old code: (index === no - 1)
						// more than 1 streamList
						// big screen other people
						this.setState({ bigScreenId: id });
						dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
					} else if (this.state.bigScreenId === id) {
						dom.setAttribute("style", `grid-area: span 12/span 24/13/25`);
					} else {
						// small screens remaining
						dom.setAttribute(
							"style",
							`grid-area: span 3/span 4/${4 + 3 * currentSmallCount++}/25;
						z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`
						);
					}

					item.player.resize && item.player.resize();
				});
			} catch (err) {}
		}
		// tile mode
		else if (this.state.displayMode === "tile") {
			let no = this.state.streamList.length;
			try {
				this.state.streamList.map((item, index) => {
					let id = item.getId();
					let dom = document.querySelector("#ag-item-" + id);
					if (!dom) {
						dom = document.createElement("section");
						dom.setAttribute("id", "ag-item-" + id);
						dom.setAttribute("class", "ag-item");
						canvas.appendChild(dom);
						item.play("ag-item-" + id);
					}
					dom.setAttribute("style", `grid-area: ${tile_canvas[no][index]}`);
					item.player.resize && item.player.resize();
				});
			} catch (err) {}
		}
		// screen share mode (tbd)
		else if (this.state.displayMode === "share") {
		}
	}

	componentWillUnmount() {
		console.log("componentWillUnmount");
		try {
			this.client && this.client.unpublish(this.localStream);
			this.localStream && this.localStream.close();
			if (this.state.stateSharing) {
				this.shareClient && this.shareClient.unpublish(this.shareStream);
				this.shareStream && this.shareStream.close();
			}
			this.client &&
				this.client.leave(
					() => {
						console.log("Client succeed to leave.");
					},
					() => {
						console.log("Client failed to leave.");
					}
				);
		} catch (err) {}
	}

	streamInit = (uid, attendeeMode, videoProfile, config) => {
		let defaultConfig = {
			streamID: uid,
			audio: true,
			video: true,
			screen: false,
		};

		switch (attendeeMode) {
			case "audio-only":
				defaultConfig.video = false;
				break;
			case "audience":
				defaultConfig.video = false;
				defaultConfig.audio = false;
				break;
			default:
			case "video":
				break;
		}

		let stream = AgoraRTC.createStream(merge(defaultConfig, config));
		stream.setVideoProfile(videoProfile);
		return stream;
	};

	subscribeStreamEvents = () => {
		let rt = this;
		rt.client.on("stream-added", function (evt) {
			let stream = evt.stream;
			console.log("New stream added: " + stream.getId());
			console.log("At " + new Date().toLocaleTimeString());
			console.log("Subscribe ", stream);
			rt.client.subscribe(stream, function (err) {
				console.log("Subscribe stream failed", err);
			});
		});

		rt.client.on("peer-online", function (evt) {
			Toast.info(`User ${evt.uid} is joining, establishing connection...`);
			console.log("peer-online", evt.uid);
		});

		rt.client.on("peer-leave", function (evt) {
			Toast.info(`User ${evt.uid} has left.`);
			console.log("Peer has left: " + evt.uid);
			console.log(new Date().toLocaleTimeString());
			console.log(evt);
			rt.removeStream(evt.uid);
		});

		rt.client.on("stream-subscribed", function (evt) {
			let stream = evt.stream;
			Toast.info(`Successfully connected with User ${stream.streamId}.`);
			console.log(new Date().toLocaleTimeString());
			console.log("Subscribe remote stream successfully: " + stream.getId());
			console.log("stream-subscribed", evt);
			rt.addStream(stream);
		});

		rt.client.on("stream-removed", function (evt) {
			let stream = evt.stream;
			console.log("Stream removed: " + stream.getId());
			console.log(new Date().toLocaleTimeString());
			console.log(evt);
			rt.removeStream(stream.getId());
		});
	};

	removeStream = (uid) => {
		this.state.streamList.map((item, index) => {
			if (item.getId() === uid) {
				item.close();
				let element = document.querySelector("#ag-item-" + uid);
				if (element) {
					element.parentNode.removeChild(element);
				}
				let tempList = [...this.state.streamList];
				tempList.splice(index, 1);
				this.setState({
					streamList: tempList,
				});
			}
		});
	};

	addStream = (stream, push = false) => {
		let repeatition = this.state.streamList.some((item) => {
			return item.getId() === stream.getId();
		});
		if (repeatition) {
			return;
		}
		if (push) {
			this.setState({
				streamList: this.state.streamList.concat([stream]),
			});
		} else {
			this.setState({
				streamList: [stream].concat(this.state.streamList),
			});
		}
	};

	handleCamera = (e) => {
		e.currentTarget.classList.toggle("off");
		this.localStream.isVideoOn() ? this.localStream.disableVideo() : this.localStream.enableVideo();
	};

	handleMic = (e) => {
		e.currentTarget.classList.toggle("off");
		this.localStream.isAudioOn() ? this.localStream.disableAudio() : this.localStream.enableAudio();
	};

	switchDisplay = (e) => {
		if (e.currentTarget.classList.contains("disabled") || this.state.streamList.length <= 1) {
			return;
		}
		if (this.state.displayMode === "pip") {
			this.setState({ displayMode: "tile" });
		} else if (this.state.displayMode === "tile") {
			this.setState({ displayMode: "pip" });
		} else if (this.state.displayMode === "share") {
			// do nothing or alert, tbd
		} else {
			console.error("Display Mode can only be tile/pip/share");
		}
	};

	hideRemote = (e) => {
		if (e.currentTarget.classList.contains("disabled") || this.state.streamList.length <= 1) {
			return;
		}
		let list;
		let id = this.state.streamList[this.state.streamList.length - 1].getId();
		list = Array.from(document.querySelectorAll(`.ag-item:not(#ag-item-${id})`));
		list.map((item) => {
			if (item.style.display !== "none") {
				item.style.display = "none";
			} else {
				item.style.display = "block";
			}
		});
	};

	handleExit = (e) => {
		if (e.currentTarget.classList.contains("disabled")) {
			return;
		}
		try {
			this.client && this.client.unpublish(this.localStream);
			this.localStream && this.localStream.close();
			if (this.state.stateSharing) {
				this.shareClient && this.shareClient.unpublish(this.shareStream);
				this.shareStream && this.shareStream.close();
			}
			this.client &&
				this.client.leave(
					() => {
						console.log("Client succeed to leave.");
					},
					() => {
						console.log("Client failed to leave.");
					}
				);
		} finally {
			this.setState({ readyState: false });
			this.client = null;
			this.localStream = null;
			// redirect to index
			window.location.hash = "";
		}
	};

	sharingScreen = (e) => {
		if (this.state.stateSharing) {
			this.shareClient && this.shareClient.unpublish(this.shareStream);
			this.shareStream && this.shareStream.close();
			this.state.stateSharing = false;
		} else {
			this.state.stateSharing = true;
			let $ = this.props;
			// init AgoraRTC local client
			this.shareClient = AgoraRTC.createClient({ mode: $.transcode });
			this.shareClient.init($.appId, async () => {
				console.log("AgoraRTC client initialized");
				this.subscribeStreamEvents();
				// const token = "00667b529ae836042cba2fa73b24dffad18IABVkpmYGxtCmCVjQ/qr5AZ2kompn7+xcPfp9zGfVmp6/gx+f9gAAAAAEAC7qLCmn8c6YQEAAQCexzph";
				const token = await CloudFunctions.agora_onCall_generateToken($.channel, $.uid);
				if (token == null) {
					window.location.hash = "/";
					Toast.error("Opps, there are some errors.");
					return;
				}
				// this.shareClient.join($.appId, $.channel, $.uid, (uid) => {
				this.shareClient.join(token, $.channel, $.uid, (uid) => {
					this.state.uid = uid;
					console.log("User " + uid + " join channel successfully");
					console.log("At " + new Date().toLocaleTimeString());
					// create local stream
					// It is not recommended to setState in function addStream
					this.shareStream = this.streamInitSharing(uid, $.attendeeMode, $.videoProfile);
					this.shareStream.init(
						() => {
							if ($.attendeeMode !== "audience") {
								this.addStream(this.shareStream, true);
								this.shareClient.publish(this.shareStream, (err) => {
									console.log("Publish local stream error: " + err);
								});
							}
							this.setState({ readyState: true });
						},
						(err) => {
							console.log("getUserMedia failed", err);
							this.setState({ readyState: true });
						}
					);
				});
			});
		}
	};

	streamInitSharing = (uid, attendeeMode, videoProfile, config) => {
		let defaultConfig = {
			streamID: uid,
			audio: true,
			video: false,
			screen: true,
		};

		switch (attendeeMode) {
			case "audio-only":
				defaultConfig.video = false;
				break;
			case "audience":
				defaultConfig.video = false;
				defaultConfig.audio = false;
				break;
			default:
			case "video":
				break;
		}

		let stream = AgoraRTC.createStream(merge(defaultConfig, config));
		stream.setVideoProfile(videoProfile);
		return stream;
	};

	render() {
		const style = {
			display: "grid",
			gridGap: "10px",
			alignItems: "center",
			justifyItems: "center",
			gridTemplateRows: "repeat(12, auto)",
			gridTemplateColumns: "repeat(24, auto)",
		};
		const videoControlBtn =
			this.props.attendeeMode === "video" ? (
				<span onClick={this.handleCamera} className="ag-btn videoControlBtn" title="Enable/Disable Video">
					<i className="ag-icon ag-icon-camera"></i>
					<i className="ag-icon ag-icon-camera-off"></i>
				</span>
			) : (
				""
			);

		const audioControlBtn =
			this.props.attendeeMode !== "audience" ? (
				<span onClick={this.handleMic} className="ag-btn audioControlBtn" title="Enable/Disable Audio">
					<i className="ag-icon ag-icon-mic"></i>
					<i className="ag-icon ag-icon-mic-off"></i>
				</span>
			) : (
				""
			);

		const switchDisplayBtn = (
			<span onClick={this.switchDisplay} className={this.state.streamList.length > 4 ? "ag-btn displayModeBtn disabled" : "ag-btn displayModeBtn"} title="Switch Display Mode">
				<i className="ag-icon ag-icon-switch-display"></i>
			</span>
		);
		const hideRemoteBtn = (
			<span className={this.state.streamList.length > 4 || this.state.displayMode !== "pip" ? "ag-btn disableRemoteBtn disabled" : "ag-btn disableRemoteBtn"} onClick={this.hideRemote} title="Hide/Unhide Remote Stream">
				<i className="ag-icon ag-icon-remove-pip"></i>
			</span>
		);
		const exitBtn = (
			<span onClick={this.handleExit} className={this.state.readyState ? "ag-btn exitBtn" : "ag-btn exitBtn disabled"} title="Exit">
				<i className="ag-icon ag-icon-leave"></i>
			</span>
		);

		return (
			<div id="ag-canvas" style={style}>
				<div className="ag-btn-group">
					{exitBtn}
					{videoControlBtn}
					{audioControlBtn}
					{
						<span onClick={this.sharingScreen} className="ag-btn shareScreenBtn" title="Share/unShare Screen">
							<i className="ag-icon ag-icon-screen-share"></i>
						</span>
					}
					{switchDisplayBtn}
					{hideRemoteBtn}
				</div>
			</div>
		);
	}
}

export default AgoraCanvas;
