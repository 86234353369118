import "./index/index.css";

import { Link } from "react-router-dom";
import React from "react";

const NotFound = () => (
	<div className="wrapper meeting">
		<div className="wrapper meeting container">
			<h1 className="login-title">404 - Not Found!</h1>
			<Link to="/">Go Home</Link>
		</div>
	</div>
);

export default NotFound;
