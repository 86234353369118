import { collection, doc, getFirestore, query } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { initializeApp } from "firebase/app";

const app = initializeApp({
	apiKey: "AIzaSyAjsGrogZosYoQAJ8kIP9ammtFm5YeRuFY",
	authDomain: "kabel-e118e.firebaseapp.com",
	databaseURL: "https://kabel-e118e.firebaseio.com",
	projectId: "kabel-e118e",
	storageBucket: "kabel-e118e.appspot.com",
	messagingSenderId: "765462391034",
	appId: "1:765462391034:web:80dc1cd3f8d852fb49c143",
	measurementId: "G-2F0XXN2V1X",
});
const functions = getFunctions(app, "asia-northeast1");

export class CloudFunctions {
	static async agora_onCall_generateToken(channelName, userId) {
		const agora_onCall_generateToken = httpsCallable(functions, "agora_onCall_generateToken");
		try {
			const result = await agora_onCall_generateToken({ channelName: channelName, userId: userId });
			const data = result.data.data;
			return data;
		} catch (err) {
			return null;
		}
	}
}

const db = getFirestore(app);
export class Collections {
	static matches = collection(db, "matches");
}

export const makeQuery = (col, wheres) => {
	return query(col, wheres);
};

export const makeDoc = (col: string, id: string) => {
	return doc(db, col, id);
};
