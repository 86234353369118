import "bulma/css/bulma.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import React, { Component } from "react";
import { Redirect, Route, HashRouter as Router, Switch } from "react-router-dom";

import Index from "./index";
import Invite from "./invite";
import Meeting from "./meeting";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";

class App extends Component {
	render() {
		return (
			<Router>
				<div className="full">
					<Switch>
						<Route exact path="/" component={Index} />
						<Route path="/meeting" component={Meeting} />
						<Route path="/invite" component={Invite} />
						<Route path="/404" component={NotFound} />
						<Redirect to="/404" />
					</Switch>
				</div>
				<ToastContainer />
			</Router>
		);
	}
}

export default App;
