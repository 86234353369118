import { getDoc } from "@firebase/firestore";
import { makeDoc } from "utils/Firebase";

export default class InviteService {
	static async getMatchDoc(matchId) {
		const docObj = makeDoc("matches", matchId);
		return docObj;
	}
	static async getMatch(matchId) {
		const docObj = makeDoc("matches", matchId);
		const result = await getDoc(docObj);
		return result;
	}
}
