import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

export default class Toast {
	static error(message) {
		toast.error(message, {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "dark",
		});
	}

	static info(message) {
		toast.info(message, {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			theme: "dark",
		});
	}
}
